@import "../../../common";
.section-first {
  @include bg;
  background-image: url("../../../assets/pc/bg1.png");
  height: px2rem(1211);
  .icon {
    display: inline-block;
    @include bg;
  }
  .content {
    font-size: 1rem;
    color: #09183E;
    display: inline-block;
    margin-left: px2rem(16);
  }
  .contact {
    height: px2rem(94);
    line-height: px2rem(94);
    padding: 0 px2rem(50);
    display: flex;
    justify-content: space-between;
    .icon {
      width: px2rem(40);
      height: px2rem(40);
    }
    .email, .time {
      display: flex;
      align-items: center;
    }
    .email .icon {
      background-image: url("../../../assets/pc/mail.png");
    }
    .time .icon {
      background-image: url("../../../assets/pc/time.png");
    }
  }
  .header {
    height: px2rem(176);
    background-color: rgb(252,97,88);
    display: flex;
    justify-content: space-between;
    padding-right: px2rem(50);
    .icon {
      width: px2rem(52);
      height: px2rem(52);
    }
    .logo {
      background-image: url("../../../assets/pc/logo.png");
      width: px2rem(349);
      height: 100%;
      @include bg;
    }
    .login {
      display: flex;
      .text {
        margin-right: px2rem(20);
        width: px2rem(280);
        line-height: px2rem(176);
        text-align: center;
        font-size: px2rem(38);
        color: #FFFFFF;
        cursor: pointer;
      }
      .content {
        color: #FFFFFF;
        //text-decoration: underline;
        font-size: 1rem;
        line-height: px2rem(43);
        //cursor: pointer;
      }
      .login-contact {
        //padding-top: px2rem(26);
        display: flex;
      }
    }
    .whatsapp, .phone {
      display: flex;
      align-items: center;
    }
    .whatsapp {
      //margin-bottom: px2rem(20);
      .icon {
        background-image: url("../../../assets/pc/whatsapp.png");
      }
    }
    .phone {
      .icon {
        background-image: url("../../../assets/pc/phone.png");
      }
    }
  }
  .main {
    margin-top: px2rem(251);
    margin-left: px2rem(100);
    .title {
      width: px2rem(1073);
      height: px2rem(100);
      font-size: px2rem(72);
      line-height: px2rem(100);
    }
    .sub-title {
      width: px2rem(1004);
      height: px2rem(144);
      font-size: px2rem(46);
      color: #09183E;
      line-height: px2rem(72);
      margin-top: px2rem(14);
      margin-bottom: px2rem(84);
    }
    .button {
      width: px2rem(438);
      height: px2rem(122);
      text-align: center;
      font-size: px2rem(52);
      font-weight: bold;
      color: #FE6053;
      padding-top: px2rem(25);
      cursor: pointer;
      background-image: url("../../../assets/pc/button2.png");
      @include bg;
    }
  }
}

.mobile .section-first {
  background-image: url("../../../assets/mobile/bg1.png");
  height: px2rem(501, 14);
  .content {
    margin-left: px2rem(4, 14);
  }
  .contact {
    height: px2rem(42, 14);
    line-height: px2rem(42, 14);
    padding: 0 px2rem(12, 14);
  }
  .header {
    height: px2rem(64, 14);
    padding-right: px2rem(12, 14);
    .icon {
      width: px2rem(18, 14);
      height: px2rem(18, 14);
    }
    .logo {
      background-image: url("../../../assets/mobile/logo.png");
      width: px2rem(135, 14);
    }
    .login {
      .text {
        margin-right: px2rem(16, 14);
        width: px2rem(63, 14);
        line-height: px2rem(64, 14);
        font-size: px2rem(16, 14);
      }
      .content {
        line-height: px2rem(22, 14);
        color: #FFFFFF;
      }
      .login-contact {
        padding-top: px2rem(7, 14);
      }
    }
    .whatsapp {
      margin-bottom: px2rem(6, 14);
      .icon {
        background-image: url("../../../assets/mobile/whatsapp.png");
      }
    }
    .phone {
      .icon {
        background-image: url("../../../assets/mobile/phone.png");
      }
    }
  }
  .main {
    margin-top: px2rem(27, 14);
    margin-left: px2rem(12, 14);
    .title {
      width: px2rem(204, 14);
      height: px2rem(52, 14);
      font-size: px2rem(22, 14);
      line-height: px2rem(26, 14);
      &:after {
        left: px2rem(106, 14);
        bottom: px2rem(-3, 14);
        @include red-dot-m-l;
      }
    }
    .sub-title {
      width: px2rem(204, 14);
      height: px2rem(60, 14);
      font-size: px2rem(16, 14);
      line-height: px2rem(20, 14);
      margin-top: px2rem(18, 14);
      margin-bottom: px2rem(40, 14);
    }
    .button {
      width: px2rem(122, 14);
      height: px2rem(34, 14);
      font-size: px2rem(15, 14);
      padding-top: px2rem(7, 14);
      background-image: url("../../../assets/mobile/button2.png");
    }
  }
}
