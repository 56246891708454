@import "../../../common";

.section-fourth {
  background-image: url("../../../assets/pc/bg4.png");
  @include bg;
  height: px2rem(1071);
  padding: px2rem(118) 0 0 px2rem(100);
  .title {
    height: px2rem(100);
    font-size: px2rem(72);
    font-weight: bold;
    color: #09183E;
    line-height: px2rem(100);
    margin-bottom: px2rem(61);
    &:after {
      right: px2rem(-18);
      bottom: px2rem(-9);
    }
  }
  .block-container {
    display: flex;
  }
  .block {
    background-image: url("../../../assets/pc/rect2.png");
    @include bg;
    height: px2rem(683);
    width: px2rem(502);
    display: inline-block;
    margin-right: px2rem(114);
    padding: px2rem(39) px2rem(31) 0 px2rem(31);
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    .block-title {
      font-size: px2rem(46);
      font-weight: 500;
      color: #09183E;
      &:after {
        right: px2rem(-6);
        bottom: px2rem(-4);
      }
    }
    .content {
      margin-top: px2rem(12);
      font-size: 1rem;
      color: #09183E;
      line-height: px2rem(43);
    }
    .icon {
      background-image: url("../../../assets/pc/star.png");
      @include bg;
      height: px2rem(53);
      width: px2rem(322);
      display: inline-block;
      position: absolute;
      bottom: px2rem(43);
      left: px2rem(90);
    }
  }
}

.mobile .section-fourth {
  background-image: url("../../../assets/mobile/bg4.png");
  height: px2rem(521, 14);
  padding: px2rem(26, 14) px2rem(12, 14) 0 px2rem(12, 14);
  .title {
    height: px2rem(26, 14);
    font-size: px2rem(22, 14);
    line-height: px2rem(26, 14);
    margin-bottom: px2rem(10, 14);
    &:after {
      right: px2rem(-7, 14);
      bottom: px2rem(-1, 14);
      @include red-dot-m-l;
    }
  }
  .block-container {
    display: flex;
    flex-direction: column;
  }
  .block {
    background-image: url("../../../assets/mobile/rect5.png");
    width: px2rem(351, 14);
    height: px2rem(131, 14);
    padding: px2rem(8, 14);
    margin-right: 0;
    margin-bottom: px2rem(8, 14);
    &:last-child {
      margin-bottom: 0;
      background-image: url("../../../assets/mobile/rect6.png");
      height: px2rem(161, 14);
    }
    .block-title {
      font-size: px2rem(16, 14);
      &:after {
        right: px2rem(-3, 14);
        bottom: px2rem(1, 14);
        @include red-dot-m-s;
      }
    }
    .content {
      margin-top: px2rem(6, 14);
      line-height: px2rem(15, 14);
    }
    .icon {
      background-image: url("../../../assets/mobile/star.png");
      width: px2rem(129, 14);
      height: px2rem(21, 14);
      position: unset;
      margin-top: px2rem(8, 14);
    }
  }
}
