@mixin bg {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
}

@function px2rem($px, $fontSize: 34) {
  @return calc(#{$px}rem / $fontSize)
}

@mixin red-dot-m-l {
  width: px2rem(20, 14);
  height: px2rem(20, 14);
}
@mixin red-dot-m-s {
  width: px2rem(12, 14);
  height: px2rem(12, 14);
}