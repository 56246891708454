@import "../../../common";

.section-fifth {
  background-image: url("../../../assets/pc/bg5.png");
  @include bg;
  height: px2rem(724);
  padding: px2rem(33) 0 0 px2rem(93);

  .logo {
    background-image: url("../../../assets/pc/logo2.png");
    @include bg;
    height: px2rem(108);
    width: px2rem(226);
    margin-bottom: px2rem(20);
    margin-left: px2rem(48);
  }
  .contact {
    background-image: url("../../../assets/pc/rect3.png");
    @include bg;
    height: px2rem(510);
    padding: px2rem(43) px2rem(57);
    width: px2rem(1734);
    .title {
      font-size: px2rem(46);
      line-height: px2rem(46);
      margin-bottom: px2rem(28);
      &:after {
        right: px2rem(-4);
        bottom: px2rem(-2);
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: px2rem(30);
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        width: px2rem(52);
        height: px2rem(52);
        @include bg;
        display: inline-block;
      }
      .item {
        display: flex;
        align-items: center;
        span, a {
          font-size: 1rem;
          color: #09183E;
          margin-left: px2rem(12);
        }
      }
      .icon-1 {
        background-image: url("../../../assets/pc/mail2.png");
      }
      .icon-2 {
        background-image: url("../../../assets/pc/whatsapp.png");
      }
      .icon-3 {
        background-image: url("../../../assets/pc/phone2.png");
      }
      .icon-4 {
        background-image: url("../../../assets/pc/time.png");
      }
      .link {
        font-size: 1rem;
        color: #FE6053;
        text-decoration: underline;
        cursor: pointer;
      }
      .top {
        text-decoration: unset;
      }
      .button {
        width: px2rem(407);
        height: px2rem(102);
        font-size: px2rem(40);
        text-align: center;
        font-weight: bold;
        color: #FFFFFF;
        line-height: px2rem(102);
        background-image: url("../../../assets/pc/button.png");
        @include bg;
        cursor: pointer;
      }
    }
  }


}

.mobile .section-fifth {
  background-image: url("../../../assets/mobile/bg5.png");
  height: px2rem(266, 14);
  padding: 0;

  .logo {
    background-image: url("../../../assets/mobile/logo2.png");
    width: px2rem(135, 14);
    height: px2rem(64, 14);
    margin-bottom: 0;
    margin-left: 0;
  }

  .contact-m {
    background-image: url("../../../assets/mobile/rect7.png");
    @include bg;
    width: px2rem(351, 14);
    height: px2rem(165, 14);
    margin: 0 auto;
    padding: px2rem(8, 14) 0 0 px2rem(8, 14);

    .title {
      font-size: px2rem(16, 14);
      line-height: px2rem(20, 14);
      margin-bottom: px2rem(12, 14);
      font-weight: 500;
      color: #09183E;
      &:after {
        right: px2rem(-4, 14);
        bottom: 0;
        @include red-dot-m-s;
      }
    }

    .list-m {
      display: flex;
      justify-content: flex-start;
      margin-bottom: px2rem(12, 14);
      .item {
        display: flex;
        align-items: center;
        width: 50%;
        a {
          color: #09183E;
          text-decoration: unset;
        }
      }
      .icon {
        width: px2rem(18, 14);
        height: px2rem(18, 14);
        display: inline-block;
        @include bg;
        margin-right: px2rem(4, 14);
      }
      .icon-1 {
        background-image: url("../../../assets/mobile/mail2.png");
      }
      .icon-2 {
        background-image: url("../../../assets/mobile/whatsapp.png");
      }
      .icon-3 {
        background-image: url("../../../assets/mobile/phone2.png");
      }
      .icon-4 {
        background-image: url("../../../assets/mobile/time.png");
      }
    }

    .operate-area {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .links {
        width: 50%;
        font-size: px2rem(12, 14);
        .link {
          color: #FE6053;
          line-height: 1rem;
          text-decoration: underline;
          margin-bottom: px2rem(4, 14);
          &:last-child {
            margin-bottom: 0;
          }
        }
        .top {
          text-decoration: unset;
        }
        a {
          display: block;
        }
      }
      .button {
        width: px2rem(117, 14);
        height: px2rem(30, 14);
        font-weight: bold;
        color: #FFFFFF;
        line-height: px2rem(16, 14);
        background-image: url("../../../assets/mobile/button.png");
        @include bg;
        text-align: center;
        padding-top: px2rem(6, 14);
      }
    }
  }
}
