@import "../../../common";

.section-second {
  padding: px2rem(90) px2rem(197) 0 px2rem(100);
  background-image: url("../../../assets/pc/bg2.png");
  @include bg;
  height: px2rem(1044);
  position: relative;

  .title {
    width: px2rem(1004);
    height: px2rem(174);
    font-size: px2rem(72);
    line-height: px2rem(87);
    &:after {
      left: px2rem(290);
      bottom: px2rem(-10);
    }
  }

  .list {
    width: px2rem(1146);
    margin-top: px2rem(62);

    .rect {
      background-image: url("../../../assets/pc/rect.png");
      @include bg;
      width: 100%;
      height: px2rem(140);
      margin-bottom: px2rem(28);
      display: flex;
      align-items: center;

      .icon {
        @include bg;
        width: px2rem(116);
        height: px2rem(116);
        display: inline-block;
      }
      .icon1 {
        background-image: url("../../../assets/pc/icon1.png");
      }
      .icon2 {
        background-image: url("../../../assets/pc/icon2.png");
      }
      .icon3 {
        background-image: url("../../../assets/pc/icon3.png");
      }
      .icon4 {
        background-image: url("../../../assets/pc/icon4.png");
      }

      .rect-title {
        font-size: px2rem(46);
        font-weight: 500;
        color: #09183E;
        line-height: px2rem(46);
        position: relative;
        &:after {
          right: px2rem(-5);
          bottom: px2rem(-7);
        }
      }
      .rect-sub-title {
        font-size: 1rem;
        color: #09183E;
        line-height: px2rem(43);
      }
    }
  }
  .phone {
    background-image: url("../../../assets/pc/mobile.png");
    @include bg;
    height: px2rem(808);
    width: px2rem(384);
    position: absolute;
    right: px2rem(197);
    top: px2rem(118);
  }

}

.mobile .section-second {
  padding: px2rem(26, 14) px2rem(12, 14) 0 px2rem(12, 14);
  background-image: url("../../../assets/mobile/bg2.png");
  height: px2rem(455, 14);

  .title {
    width: px2rem(351, 14);
    height: px2rem(52, 14);
    font-size: px2rem(22, 14);
    line-height: px2rem(26, 14);
    &:after {
      left: px2rem(57, 14);
      bottom: px2rem(-2, 14);
      @include red-dot-m-l;
    }
  }

  .list {
    width: px2rem(212, 14);
    margin-top: px2rem(10, 14);
    .rect {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: px2rem(8, 14);
      &:first-child {
        background-image: url("../../../assets/mobile/rect1.png");
        height: px2rem(101, 14);
      }
      &:nth-child(2) {
        background-image: url("../../../assets/mobile/rect2.png");
        height: px2rem(72, 14);
      }
      &:nth-child(3) {
        background-image: url("../../../assets/mobile/rect3.png");
        height: px2rem(70, 14);
      }
      &:nth-child(4) {
        background-image: url("../../../assets/mobile/rect4.png");
        height: px2rem(88, 14);
      }
      .icon {
        width: px2rem(42, 14);
        height: px2rem(42, 14);
      }
      .icon1 {
        background-image: url("../../../assets/mobile/icon1.png");
      }
      .icon2 {
        background-image: url("../../../assets/mobile/icon2.png");
      }
      .icon3 {
        background-image: url("../../../assets/mobile/icon3.png");
      }
      .icon4 {
        background-image: url("../../../assets/mobile/icon4.png");
      }
      .header {
        display: flex;
        align-items: center;
        margin-top: px2rem(3, 14);
      }

      .rect-title {
        font-size: px2rem(16, 14);
        line-height: px2rem(20, 14);
        &:after {
          right: px2rem(-3, 14);
          bottom: px2rem(2, 14);
        }
      }
      .rect-sub-title {
        line-height: 1rem;
        margin-left: px2rem(8, 14);
      }
    }
  }
  .phone {
    background-image: url("../../../assets/mobile/mobile.png");
    height: px2rem(258, 14);
    width: px2rem(123, 14);
    right: px2rem(12, 14);
    top: px2rem(137, 14);
  }

}
