@import "../../common";
.red-dot-title {
  font-weight: bold;
  color: #09183E;
  position: relative;
  display: inline-block;
  &:after {
    position: absolute;
    content: "";
    background-color: #FE6053;
    border-radius: 100%;
    right: 0;
    bottom: 0;
  }
  &.small {
    &:after {
      width: px2rem(26);
      height: px2rem(26);
    }
  }
  &.large {
    &:after {
      width: px2rem(64);
      height: px2rem(64);
    }
  }
  .title-inner {
    position: relative;
    z-index: 1;
  }
}