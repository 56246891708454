@import "../../../common";

.section-third {
  padding: px2rem(119) 0 0 px2rem(100);
  background-image: url("../../../assets/pc/bg3.png");
  @include bg;
  height: px2rem(830);
  .title {
    display: inline-block;
    height: px2rem(100);
    font-size: px2rem(72);
    font-weight: bold;
    color: #09183E;
    line-height: px2rem(100);
    &:after {
      right: px2rem(-9);
      bottom: px2rem(-4);
    }
  }
  .first {
    margin-top: px2rem(31);
    display: flex;
    flex-wrap: wrap;
    div {
      position: relative;
      text-align: center;
      p {
        font-size: 1rem;
        font-weight: bold;
        color: #09183E;
        line-height: px2rem(38);
        max-width: px2rem(298);
        width: px2rem(298);
        margin-top: px2rem(-14);
      }
      .icon {
        width: px2rem(210);
        height: px2rem(210);
        display: inline-block;
        @include bg;
      }
    }
    .arrow {
      width: px2rem(156);
      height: px2rem(104);
      background-image: url("../../../assets/pc/arrow2.png");
      @include bg;
      margin-right: px2rem(81);
      margin-top: px2rem(82);
      margin-left: px2rem(385);
    }
    .pic-1 .icon {
      background-image: url("../../../assets/pc/icon5.png");
    }
    .pic-2 .icon {
      background-image: url("../../../assets/pc/icon7.png");
    }
  }
  .second {
    margin-left: px2rem(185);
    display: flex;
    flex-wrap: wrap;
    .arrow-1, .arrow-2 {
      width: px2rem(156);
      height: px2rem(104);
    }
    .arrow-1 {
      margin-right: px2rem(119);
      margin-left: px2rem(40);
      background-image: url("../../../assets/pc/arrow1.png");
      @include bg;
    }
    .arrow-2 {
      margin-left: px2rem(384);
      margin-right: px2rem(41);
      margin-top: px2rem(12);
      background-image: url("../../../assets/pc/arrow1.png");
      @include bg;
    }
    div {
      position: relative;
      text-align: center;
      p {
        font-size: 1rem;
        font-weight: bold;
        color: #09183E;
        line-height: px2rem(38);
        max-width: px2rem(298);
        width: px2rem(298);
        margin-top: px2rem(-14);
      }
      .icon {
        width: px2rem(210);
        height: px2rem(210);
        display: inline-block;
        @include bg;
      }
    }
    .pic-1 .icon {
      background-image: url("../../../assets/pc/icon6.png");
    }
    .pic-2 .icon {
      background-image: url("../../../assets/pc/icon8.png");
    }

  }

}

.mobile .section-third {
  padding: px2rem(26, 14) px2rem(12, 14) 0 px2rem(12, 14);
  background-image: url("../../../assets/mobile/bg3.png");
  height: px2rem(350, 14);
  .title {
    height: px2rem(26, 14);
    font-size: px2rem(22, 14);
    line-height: px2rem(26, 14);
    margin-bottom: px2rem(10, 14);
    &:after {
      right: px2rem(-9);
      bottom: px2rem(-4);
      @include red-dot-m-l;
    }
  }
  .pic-1, .pic-2 {
    p {
      margin-top: px2rem(-10, 14);
    }
  }
  .row-1, .row-3 {
    margin: 0 px2rem(33, 14) 0 px2rem(33, 14);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pic-1, .pic-2 {
      width: px2rem(82, 14);
      text-align: center;
      .icon {
        width: px2rem(68, 14);
        height: px2rem(68, 14);
        display: inline-block;
        background-image: url("../../../assets/mobile/icon5.png");
        @include bg;
      }
    }
    .arrow {
      width: px2rem(41, 14);
      height: px2rem(28, 14);
      display: inline-block;
      background-image: url("../../../assets/mobile/arrow1.png");
      @include bg;
    }
    .pic-2 {
      .icon {
        background-image: url("../../../assets/mobile/icon6.png");
      }
    }
  }
  .row-2 {
    display: flex;
    justify-content: flex-end;
    margin-right: px2rem(63, 14);
    margin-top: px2rem(22, 14);
    .arrow {
      width: px2rem(27, 14);
      height: px2rem(40, 14);
      display: inline-block;
      background-image: url("../../../assets/mobile/arrow2.png");
      @include bg;
    }
  }
  .row-3 {
    margin-right: px2rem(22, 14);
    margin-top: px2rem(13, 14);
    .pic-1 {
      .icon {
        background-image: url("../../../assets/mobile/icon7.png");
      }
    }
    .arrow {
      background-image: url("../../../assets/mobile/arrow3.png");
    }
    .pic-2 {
      width: px2rem(104, 14);
      .icon {
        background-image: url("../../../assets/mobile/icon8.png");
      }
    }
  }


}
